import { showPopUpdatePassword } from './updatePassword/asyncIndex'
import schttp from 'public/src/services/schttp'
import { setSessionStorage, getSessionStorage } from '@shein/common-function'
import { eachHandler } from './utils'

export const userModule = {
  data: {
    default_country: '',
    fetched_default_country: false,
  },
  // 更新导航区登录状态
  update_top_login: function() {
    var self = this
    window.GB_USER_DROPDOWN && window.GB_USER_DROPDOWN.update()
    self.get_default_country('force')
  },
  //获取首单优惠数据、货币，平替check_login_promise,保留freeShippingTips、currency
  async check_freeShippingTips_promise() {
    var url = '/api/common/freeShippingTips/get'
    const data = await schttp({
      method: 'POST',
      url
    })
    return data
  },
  // 获取国家
  async get_default_country (force) {
    var self = this
    if (force || !self.data.fetched_default_country) {
      self.data.fetched_default_country = true
      self.data.default_country = ''
      return self.data.default_country
    } else {
      return self.data.default_country
    }
  },
  //获取用户信息
  async get_user_profile () {
    var self = this
    const res = await schttp({
      url: 'api/user/account_setting/isCouponActivityOpen/get',
    })
    if(res.code == 0 && res.info && res.info.result && (res.info.result.pointActOpen == 1 || res.info.result.couponActOpen  == 1)){ 
      document.querySelectorAll('.j-profile-nopenum').forEach(node => {
        node.classList.remove('she-hide')
      })
      self.show_account_tips(1)
    }
  },
  //有未读信息红点展示
  show_account_tips: function(show){
    if(!getSessionStorage('isCheckedAccountTips') && show){
      document.querySelectorAll('.j-account-nopenum').forEach(node => {
        node.classList.remove('she-hide')
      })
    }
  },
  hide_account_tips: function(){
    eachHandler({
      selector: '.j-header-username-icon',
      type: 'mouseenter',
      cb: function(){
        const doms = document.querySelectorAll('.j-account-nopenum')
        const result = eachHandler({
          selector: doms,
          type: 'hasClass',
          className: 'she-hide'
        })
        if(!result){
          eachHandler({
            selector: doms,
            type: 'addClass',
            className: 'she-hide'
          })
          setSessionStorage({ key: 'isCheckedAccountTips', value: '1' })
        }
      }
    })
  },
  // 设置调查问卷入口红点
  async toggle_account_survey_tips() {
    var self = this
    const res = await schttp({
      url: '/user/survey',
    })
    if(res.code == '0' && res.info && res.info.questionnaires && res.info.questionnaires.length){
      self.show_account_tips(1)
      self.hide_account_tips()
      if(window.location.href.indexOf('/user/survey') > -1){
        eachHandler({
          selector: '.j-survey-dot',
          type: 'addClass',
          className: 'she-hide'
        })
      }else{
        eachHandler({
          selector: '.j-survey-dot',
          type: 'removeClass',
          className: 'she-hide'
        })
      }
    }
    eachHandler({
      selector: '.j-header-survey',
      type: 'click',
      cb: () => {
        eachHandler({
          selector: '.j-survey-dot',
          type: 'addClass',
          className: 'she-hide'
        })
      }
    })
  }
}
